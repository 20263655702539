import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import clsx from 'clsx';

export type AlertSeverity = 'error' | 'success' | 'warning' | 'info';
export type BorderPosition = 'top' | 'left' | 'right' | 'bottom';

interface AlertProps {
  type?: AlertSeverity;
  title?: string;
  message?: string;
  children?: React.ReactNode;
  borderPosition?: BorderPosition;
}

export default function Alert({
  type = 'info',
  title,
  message,
  children,
  borderPosition = 'top',
}: AlertProps) {
  let icon = <InformationCircleIcon className="h-6 w-6 shrink-0" />;

  switch (type) {
    case 'error':
      icon = <XCircleIcon className="h-8 w-8 shrink-0" />;
      break;
    case 'warning':
      icon = <ExclamationCircleIcon className="h-6 w-6 shrink-0" />;
      break;
    case 'success':
      icon = <CheckCircleIcon className="h-8 w-8 shrink-0" />;
      break;
  }

  const className = clsx(
    'rounded px-4 py-3 shadow-md',
    {
      'border-l-4': borderPosition === 'left',
      'border-r-4': borderPosition === 'right',
      'border-t-4': borderPosition === 'top',
      'border-b-4': borderPosition === 'bottom',
    },
    type === 'success' && 'border-teal-500 bg-teal-100 text-teal-900',
    type === 'info' && 'border-blue-500 bg-blue-100 text-blue-900',
    type === 'error' && 'border-red-500 bg-red-100 text-red-900',
    type === 'warning' && 'border-orange-500 bg-orange-100 text-orange-900',
  );
  return (
    <div className={className} role="alert">
      <div className="flex">
        <div className="mr-2 py-1">{icon}</div>
        <div className="flex flex-col justify-center">
          {title && <p className="font-bold">{title}</p>}
          {message && <p className="text-sm">{message} </p>}
          {children}
        </div>
      </div>
    </div>
  );
}

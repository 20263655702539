'use client';
import AppButton from '@/app/_ui/buttons/AppButton';
import Alert from '@/app/_ui/common/Alert';
import Dialog from '@/app/_ui/dialogs/Dialog';
import TextInput from '@/app/_ui/inputs/TextInput';
import React, { createContext, useCallback, useContext, useState } from 'react';

interface ConfirmOptions {
  title?: string;
  description?: string;
  confirmMessage?: string;
  cancelMessage?: string;
  confirmButtonType?: 'error' | 'success' | 'warning' | 'white' | 'transparent';
  isDangerous?: boolean;
  dangerousConfirmText?: string; // Text that must be typed to confirm dangerous actions
  dangerousAlertTitle?: string; // Custom title for danger alert
  dangerousAlertMessage?: string; // Custom message for danger alert
  onConfirm?: () => void;
  onCancel?: () => void;
}

interface ConfirmContextType {
  confirm: (options: ConfirmOptions) => void;
}

const ConfirmContext = createContext<ConfirmContextType | undefined>(undefined);

export const useConfirm = () => {
  const context = useContext(ConfirmContext);
  if (!context) {
    throw new Error('useConfirm must be used within a ConfirmProvider');
  }
  return context.confirm;
};

export const ConfirmProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [confirmState, setConfirmState] = useState<{
    isOpen: boolean;
    options: ConfirmOptions;
  }>({
    isOpen: false,
    options: {},
  });

  const [dangerousConfirmInput, setDangerousConfirmInput] = useState('');

  const confirm = useCallback((options: ConfirmOptions): void => {
    setConfirmState({
      isOpen: true,
      options,
    });
    setDangerousConfirmInput('');
  }, []);

  const handleClose = useCallback(() => {
    setConfirmState((prev) => ({ ...prev, isOpen: false }));
    setDangerousConfirmInput('');
    confirmState.options.onCancel?.();
  }, [confirmState]);

  const handleConfirm = useCallback(() => {
    if (
      confirmState.options.isDangerous &&
      confirmState.options.dangerousConfirmText
    ) {
      if (
        dangerousConfirmInput.trim() !==
        confirmState.options.dangerousConfirmText
      ) {
        return;
      }
    }
    setConfirmState((prev) => ({ ...prev, isOpen: false }));
    setDangerousConfirmInput('');
    confirmState.options.onConfirm?.();
  }, [confirmState, dangerousConfirmInput]);

  const isConfirmDisabled = Boolean(
    confirmState.options.isDangerous &&
      confirmState.options.dangerousConfirmText &&
      dangerousConfirmInput.trim() !==
        confirmState.options.dangerousConfirmText,
  );

  return (
    <ConfirmContext.Provider value={{ confirm }}>
      {children}
      <Dialog
        isOpen={confirmState.isOpen}
        onClose={handleClose}
        title={confirmState.options.title ?? 'Confirmation'}
        description={
          <div className="space-y-4">
            <p>{confirmState.options.description}</p>

            {(confirmState.options.dangerousAlertTitle ??
              confirmState.options.dangerousAlertMessage) && (
              <Alert
                type="warning"
                title={confirmState.options.dangerousAlertTitle}
                message={confirmState.options.dangerousAlertMessage}
                borderPosition="left"
              />
            )}

            {confirmState.options.isDangerous &&
              confirmState.options.dangerousConfirmText && (
                <div className="mt-4">
                  <TextInput
                    name="dangerous-confirm"
                    label={`To confirm deletion, please type: ${confirmState.options.dangerousConfirmText}`}
                    value={dangerousConfirmInput}
                    onChange={(e) => setDangerousConfirmInput(e.target.value)}
                    variant="primary"
                    error={
                      dangerousConfirmInput.length > 0 &&
                      dangerousConfirmInput.trim() !==
                        confirmState.options.dangerousConfirmText
                    }
                    message={
                      dangerousConfirmInput.length > 0 &&
                      dangerousConfirmInput.trim() !==
                        confirmState.options.dangerousConfirmText
                        ? "Text doesn't match. Please try again."
                        : undefined
                    }
                    placeholder="Type the confirmation text"
                    inputProps={{
                      autoComplete: 'off',
                      spellCheck: 'false',
                    }}
                  />
                </div>
              )}
          </div>
        }
      >
        <div className="mt-4 flex justify-end space-x-3">
          <AppButton
            onClick={handleClose}
            text={confirmState.options.cancelMessage ?? 'Cancel'}
            variant="outlined"
          />
          <AppButton
            onClick={handleConfirm}
            text={confirmState.options.confirmMessage ?? 'Confirm'}
            variant="primary"
            color={confirmState.options.confirmButtonType}
            disabled={isConfirmDisabled}
          />
        </div>
      </Dialog>
    </ConfirmContext.Provider>
  );
};
